import { request } from './request'

// 获取签到规则列表
export function getSignRuleList (params) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/sign-rule/list',
    method: 'get',
    params
  })
}

// 删除签到规则
export function delSignRuleRequest (id) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: `/sign-rule/${id}`,
    method: 'delete'
  })
}

// 添加店铺
export function addSignRuleRequest (info) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/sign-rule',
    method: 'post',
    data: { ...info }
  })
}

// 修改店铺
export function updateSignRuleRequest (info) {
  return request({
    baseUrl: window.g.TASK_URL,
    url: '/sign-rule',
    method: 'put',
    data: {
      day: info.day,
      coin: info.coin
    }
  })
}
