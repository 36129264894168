<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>任务管理</template>
      <template v-slot:secondMenu>签到规则</template>
    </breadcrumb-nav>
    <el-button
      type="primary"
      icon="el-icon-plus"
      style="margin: 10px 0px"
      @click="showDialog"
    >添加
    </el-button>
    <el-table style="width: 100%" border :data="list">
      <el-table-column type="index" label="序号" width="80px" align="center">
      </el-table-column>
      <el-table-column prop="day" label="签到天数" width="width">
      </el-table-column>
      <el-table-column prop="coin" label="奖励金币" width="width">
      </el-table-column>
      <el-table-column prop="prop" label="操作" width="width">
        <template slot-scope="{row}">
          <el-button
            type="warning"
            icon="el-icon-edit"
            size="mini"
            @click="updateRule(row)"
          >修改</el-button
          >
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            @click="delRule(row)"
          >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="ruleForm.id ? '修改规则' : '添加规则'"
      :visible.sync="dialogFormVisible"
    >
      <!-- form表单 :model属性，这个属性的作用是,把表单的数据收集到那个对象的身上 ，将来表单验证，也需要这个属性-->
      <el-form style="width: 80%" :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="签到天数" label-width="100px" prop="day">
          <el-input type="Number" v-model.number="ruleForm.day"></el-input>
        </el-form-item>
        <el-form-item label="签到奖励" label-width="100px" prop="coin">
          <el-input type="Number" v-model.number="ruleForm.coin"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdateRule"
        >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>

import BreadcrumbNav from '../common/BreadcrumbNav'
import {
  delSignRuleRequest,
  getSignRuleList,
  addSignRuleRequest,
  updateSignRuleRequest
} from '../../network/sign'

export default {
  name: 'Sign',
  components: {
    BreadcrumbNav
  },
  data () {
    return {
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      // 对话框显示与隐藏控制的属性
      dialogFormVisible: false,
      // 收集签到规则信息
      ruleForm: {
        day: 0,
        coin: 0
      },
      // 表单验证规则
      rules: {
        // require:必须要校验字段（前面五角星有关系）  message 提示信息    trigger:用户行为设置（事件的设置:blur、change）
        day: [
          { required: true, message: '请输入签到天数', trigger: 'blur' }
        ],
        //
        coin: [{ required: true, message: '请输入奖励金币', trigger: 'blur' }]
      }
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getRuleList()
  },
  methods: {
    getRuleList () {
      getSignRuleList().then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取规则列表失败', 'error')
        }
        this.list = result.data.list
        this.total = result.data.total
      })
    },
    updateRule (row) {
      console.log('update row:', row)
      this.dialogFormVisible = true
      // 将已有的规则信息赋值给ruleForm进行展示
      // 将服务器返回规则的信息，直接赋值给了ruleForm进行展示。
      // 也就是ruleForm存储即为服务器返回规则信息
      this.ruleForm = { ...row }
    },
    delRule (row) {
      console.log('del row:', row)
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delSignRuleRequest(row.day).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('删除失败', 'error')
          }
          this.alertMessage('删除成功', 'success')
          this.getRuleList()
        })
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    },
    showDialog () {
      this.dialogFormVisible = true
      this.ruleForm = { day: 0, coin: 0 }
    },
    updateRule2Srv () {
      updateSignRuleRequest(this.ruleForm).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改签到规则失败', 'error')
        }
        this.alertMessage('修改签到规则成功', 'success')
        this.getRuleList()
      })
    },
    addRule2Srv () {
      addSignRuleRequest(this.ruleForm).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加签到规则失败', 'error')
        }
        this.alertMessage('添加签到规则成功', 'success')
        this.getRuleList()
      })
    },
    addOrUpdateRule () {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return
        this.dialogFormVisible = false
        console.log('this.ruleForm:', this.ruleForm)
        if (this.ruleForm.id) {
          this.updateRule2Srv()
        } else {
          this.addRule2Srv()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
